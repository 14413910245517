<template>
  <b-form @submit.prevent>
    <b-alert
      v-if="doctorData.main_speciality === undefined"
      variant="danger"
      show
    >
      <div class="alert-body">
        <span><strong>Внимание!</strong> Выберите основную специальность у врача и сохраните страницу!</span>
      </div>
    </b-alert>

    <b-alert
      v-if="(doctorData.redirect_id !== null && doctorData.redirect_id !== undefined) && (doctorData.redirect_doctor !== null && doctorData.redirect_doctor !== undefined)"
      variant="danger"
      show
    >
      <div class="alert-body">
        <span><strong>Внимание!</strong> Врач редиректит на <router-link :to="{name: 'doctors-update', params: {id: doctorData.redirect_doctor.id}}"><b>{{ doctorData.redirect_doctor.full_name }}</b></router-link>!</span>
      </div>
    </b-alert>

    <b-row>
      <b-col
        cols="3"
        class="text-center"
      >
        <b-row>
          <b-col>
            <b-avatar
              id="photoPreview"
              ref="previewEl"
              :src="photoPreview"
              size="200px"
              variant="light-primary"
              rounded
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-button
              variant="primary"
              size="sm"
              class="mt-1 btn-block mb-1"
              @click="openFileDialog('inputPhoto')"
            >
              <span class="d-sm-inline">Выбрать</span>
              <b-form-file
                ref="inputPhoto"
                accept="image/jpeg, image/png, image/gif"
                size="sm"
                class="text-left d-none"
                @change="fileChanged"
              />
            </b-button>
          </b-col>
        </b-row>

      </b-col>

      <b-col cols="9">
        <b-form-group
          label="Ф.И.О.:"
          label-for="full_name"
        >
          <ValidationProvider
            #default="{ errors }"
            name="full_name"
            vid="full_name"
          >
            <b-form-input
              id="name"
              v-model="doctorData.full_name"
              autocomplete="off"
              placeholder="Введите ФИО"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-form-group>

        <b-form-group
          label="ЧПУ URL:"
          label-for="slug"
        >
          <ValidationProvider
            #default="{ errors }"
            name="slug"
            vid="slug"
          >
            <b-input-group>
              <b-form-input
                id="slug"
                v-model="doctorData.slug"
                autocomplete="off"
                placeholder="Введите ЧПУ URL"
              />
              <b-input-group-append v-if="doctorData.id && doctorData.slug && (genUrl(doctorData) !== null)">
                <b-button
                  variant="primary"
                  :href="genUrl(doctorData)"
                  target="_blank"
                >
                  Перейти
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-form-group>

        <b-form-group
          label="Город:"
          label-for="city_id"
        >
          <ValidationProvider
            #default="{ errors }"
            name="city_id"
            vid="city_id"
          >
            <v-select
              v-model="doctorData.city_id"
              label="name"
              :reduce="option => option.id"
              :options="citiesList"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group
          label="Пол:"
          label-for="gender"
        >
          <ValidationProvider
            #default="{ errors }"
            name="gender"
            vid="gender"
          >
            <b-form-select
              id="city_id"
              v-model="doctorData.gender"
              value-field="id"
              text-field="value"
              :options="[{id: null, value: 'Не выбрано'}, ...DoctorsConstants.GENDER_LIST]"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group
          label="E-Mail:"
          label-for="email"
        >
          <ValidationProvider
            #default="{ errors }"
            name="email"
            vid="email"
          >
            <b-form-input
              id="email"
              v-model="doctorData.email"
              autocomplete="off"
              placeholder="Введите E-mail"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group
          label="Основная специальность:"
          label-for="main_speciality"
        >
          <ValidationProvider
            #default="{ errors }"
            name="main_speciality"
            vid="main_speciality"
          >
            <b-form-select
              id="main_speciality"
              v-model="doctorData.main_speciality"
              value-field="id"
              text-field="name"
              :options="[{id: null, name: 'Не выбрано'}, ...specialitiesList]"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group
          label="Дополнительные специальности:"
          label-for="other_specialities"
        >
          <ValidationProvider
            #default="{ errors }"
            name="other_specialities"
            vid="other_specialities"
          >
            <v-select
              v-model="doctorData.other_specialities"
              multiple
              label="name"
              :reduce="option => option.id"
              :options="specialitiesList"
              placeholder="Выберите доп специальности"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group
          label="Категория:"
          label-for="category"
        >
          <ValidationProvider
            #default="{ errors }"
            name="category"
            vid="category"
          >
            <b-form-select
              id="category"
              v-model="doctorData.category"
              value-field="id"
              text-field="value"
              :options="[{id: null, value: 'Не выбрано'}, ...DoctorsConstants.CATEGORY_LIST]"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group
          label="Степень:"
          label-for="degree"
        >
          <ValidationProvider
            #default="{ errors }"
            name="degree"
            vid="degree"
          >
            <b-form-select
              id="degree"
              v-model="doctorData.degree"
              value-field="id"
              text-field="value"
              :options="[{id: null, value: 'Не выбрано'}, ...DoctorsConstants.DEGREE_LIST]"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group
          label="Стаж с:"
          label-for="experience_start"
        >
          <ValidationProvider
            #default="{ errors }"
            name="experience_start"
            vid="experience_start"
          >
            <b-form-select
              id="experience_start"
              v-model="doctorData.experience_start"
              value-field="id"
              text-field="value"
              :options="stagList"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group
          label="Тип врача:"
          label-for="type"
        >
          <ValidationProvider
            #default="{ errors }"
            name="type"
            vid="type"
          >
            <b-form-select
              id="type"
              v-model="doctorData.type"
              value-field="id"
              text-field="value"
              :options="[{id: null, value: 'Не выбрано'}, ...DoctorsConstants.TYPE_LIST]"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group
          label="Места работы (клиники):"
          label-for="clinics"
        >
          <ValidationProvider
            #default="{ errors }"
            name="cliniсs"
            vid="cliniсs"
          >
            <v-select
              id="clinics"
              v-model="doctorData.clinic_ids"
              label="name"
              :reduce="option => option.id"
              :options="clinic_options"
              multiple
              :disabled="doctorData.city_id === null"
              @search="onSearch"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-form-group>

        <div class="mb-2">
          <span
            v-for="(clinic_id, index) in doctorData.clinic_ids"
            v-show="doctorData.clinic_ids.length > 0"
            :key="clinic_id.id"
          >
            <router-link
              :to="{name: 'clinics-update', params: {id: clinic_id}}"
              target="_blank"
            >
              {{ getNameClinicById(clinic_id) }}<span v-if="index !== doctorData.clinic_ids.length - 1">,&nbsp;&nbsp;</span>
            </router-link>
          </span>
        </div>
      </b-col>

      <b-col cols="6">
        <b-form-group
          label="Образование:"
          label-for="education"
        >
          <ValidationProvider
            #default="{ errors }"
            name="education"
            vid="education"
          >
            <b-form-textarea
              id="education"
              ref="doctorData.education"
              v-model="doctorData.education"
              placeholder="Введите описание образование"
              rows="3"
            />
            <div class="d-flex justify-content-between align-items-baseline">
              <fast-insert
                :input-ref="$refs['doctorData.education']"
                :suggestions="configSuggestions"
                input-ref-name="doctorData.education"
                @fastInsertSelected="onSelectedFastInsertSuggestion"
              />
            </div>
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group
          label="Курсы:"
          label-for="courses"
        >
          <ValidationProvider
            #default="{ errors }"
            name="courses"
            vid="courses"
          >
            <b-form-textarea
              id="courses"
              ref="doctorData.courses"
              v-model="doctorData.courses"
              placeholder="Введите описание курсов"
              rows="3"
            />
            <div class="d-flex justify-content-between align-items-baseline">
              <fast-insert
                :input-ref="$refs['doctorData.courses']"
                :suggestions="configSuggestions"
                input-ref-name="doctorData.courses"
                @fastInsertSelected="onSelectedFastInsertSuggestion"
              />
            </div>
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group
          label="Дополнительно:"
          label-for="description"
        >
          <ValidationProvider
            #default="{ errors }"
            name="description"
            vid="description"
          >
            <b-form-textarea
              id="description"
              v-model="doctorData.description"
              placeholder="Введите описание"
              rows="3"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group
          label="Описание (для нас):"
          label-for="note"
        >
          <ValidationProvider
            #default="{ errors }"
            name="note"
            vid="note"
          >
            <b-form-textarea
              id="note"
              v-model="doctorData.note"
              placeholder="Введите заметку (только для нас)"
              rows="3"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group
          label="ID docdoc:"
          label-for="id_docdoc"
        >
          <ValidationProvider
            #default="{ errors }"
            name="id_docdoc"
            vid="id_docdoc"
          >
            <b-form-input
              id="id_docdoc"
              v-model="doctorData.id_docdoc"
              autocomplete="off"
              placeholder="ID в базе Сбер здоровье"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group
          label="Тип docdoc:"
          label-for="status_docdoc"
        >
          <ValidationProvider
            #default="{ errors }"
            name="status_docdoc"
            vid="status_docdoc"
          >
            <b-form-select
              id="is_show"
              v-model="doctorData.status_docdoc"
              value-field="id"
              text-field="value"
              :options="DoctorsConstants.TYPE_DOCDOC_LIST"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group
          label="Статус доктора:"
          label-for="is_show"
        >
          <ValidationProvider
            #default="{ errors }"
            name="is_show"
            vid="is_show"
          >
            <b-form-select
              id="is_show"
              v-model="doctorData.is_show"
              :disabled="doctorData.redirect_id ? true : false"
              value-field="id"
              text-field="value"
              :options="DoctorsConstants.STATUS_DOCTORS_LIST"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group
          label="Редирект на:"
          label-for="redirect_id"
        >
          <ValidationProvider
            #default="{ errors }"
            name="redirect_id"
            vid="redirect_id"
          >
            <v-select
              id="doctor_id"
              v-model="doctorData.redirect_id"
              label="full_name"
              :reduce="option => option.id"
              :options="redirect_options"
              :disabled="!doctorData.city_id"
              @search="onSearchDoctors"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-form-group>
      </b-col>

    </b-row>

    <div class="d-flex flex-row-reverse">
      <div>
        <b-button
          variant="primary"
          class="float-right"
          @click="formSubmitClose"
        >
          {{ doctorData.id ? 'Обновить и закрыть' : 'Создать и закрыть' }}
        </b-button>
      </div>
      <div v-if="doctorData.id">
        <b-button
          variant="primary"
          class="mr-1"
          @click="formSubmitReload"
        >
          Обновить
        </b-button>
      </div>
      <div>
        <b-button
          variant="flat-danger"
          class="mr-1"
          @click="$router.back()"
        >
          Отменить
        </b-button>
      </div>
    </div>
  </b-form>
</template>

<script>
import {
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BForm,
  BFormGroup,
  BButton,
  BFormSelect,
  BFormTextarea,
  BAvatar,
  BFormFile,
  BAlert,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import DoctorsConstants from '@/consts/doctors'
import _ from 'lodash'
import axiosIns from '@/libs/axios'

export default {
  name: 'DoctorsForm',
  components: {
    BButton,
    BForm,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BFormGroup,
    vSelect,
    BFormSelect,
    BFormTextarea,
    BAvatar,
    BFormFile,
    BAlert,
  },
  props: {
    doctorData: {
      type: Object,
      require: true,
      default: () => {},
    },
    citiesList: {
      type: Array,
      require: true,
      default: () => [],
    },
    specialitiesList: {
      type: Array,
      require: true,
      default: () => [],
    },
    clinicOptions: {
      type: Array,
      require: true,
      default: () => [],
    },
    doctorOptions: {
      type: Array,
      require: true,
      default: () => [],
    },
    selectedOptions: {
      type: Array,
      require: true,
      default: () => [],
    },
  },
  data() {
    return {
      DoctorsConstants,
      stagList: [],
      avatar: null,
      configSuggestions: ['<li></li>', '<span></span>', '<span class="e_text"></span>'],
    }
  },
  computed: {
    photoPreview() {
      return this.avatar ?? (typeof this.doctorData.photo === 'string' ? (this.doctorData.photo) : null)
    },
    clinic_options() {
      return this.clinicOptions.map(({ name, id }) => ({ name, id }))
    },
    redirect_options() {
      return this.doctorOptions.map(({ full_name, id }) => ({ full_name, id }))
    },
  },
  watch: {
    'doctorData.clinic_ids': {
      handler(val) {
        const clinicIds = _.pickBy(val, _.identity)
        const clinicsObject = []

        _.each(clinicIds, element => {
          // eslint-disable-next-line radix
          const findEl = _.find(this.clinic_options, cl => cl.id === parseInt(element))
          clinicsObject.push(findEl)
        })
        this.$emit('update:selectedOptions', clinicsObject)
      },
    },
    'doctorData.redirect_id': {
      handler(val) {
        console.log(val)
        if (val === null || val === undefined) {
          this.doctorData.is_show = DoctorsConstants.STATUS_DOCTORS_IDS.SHOW_STATUS_VISIBLE
        } else {
          this.doctorData.is_show = DoctorsConstants.STATUS_DOCTORS_IDS.SHOW_STATUS_MERGED
        }
      },
    },
  },
  mounted() {
    this.generateStag()
  },
  methods: {
    openFileDialog(ref) {
      this.$refs[ref].$el.getElementsByTagName('input')[0].click()
    },
    fileChanged(e) {
      const selectedFile = e.target.files[0]
      this.avatar = URL.createObjectURL(selectedFile)
      this.doctorData.photo = selectedFile
    },
    generateStag() {
      const yearEnd = 1960
      const yearStart = new Date().getFullYear()

      // eslint-disable-next-line no-plusplus
      for (let i = yearStart; i >= yearEnd; i--) {
        this.stagList.push(i)
      }

      return this.stagList
    },
    // eslint-disable-next-line consistent-return
    onSearch(search, loading) {
      if (search.length) {
        loading(true)
        return this.search(loading, search, this.doctorData, this)
      }
    },
    search: _.debounce(async (loading, search, doctorData, vm) => {
      const res = await axiosIns({
        method: 'GET',
        url: `/clinics/search?search=${search}&city_id=${doctorData.city_id}`,
      })

      const selectedClinic = vm.selectedOptions.map(({ name, id }) => ({ name, id }))
      const responseList = res.data.data.map(({ name, id }) => ({ name, id }))
      const mergeList = _.concat(responseList, selectedClinic)
      const uniqueRes = _.uniqBy(mergeList, e => e.id)

      vm.$emit('update:clinicOptions', uniqueRes)
      loading(false)
    }, 350),
    // eslint-disable-next-line consistent-return
    onSearchDoctors(search, loading) {
      if (search.length) {
        loading(true)
        return this.searchDoctors(loading, search, this.doctorData, this)
      }
    },
    searchDoctors: _.debounce(async (loading, search, doctorData, vm) => {
      const res = await axiosIns({
        method: 'GET',
        url: `/doctors/search?search=${search}&doctor_id=${vm.$route.params.id}`,
      })

      // eslint-disable-next-line camelcase
      const selectedClinic = vm.doctorOptions.map((full_name, id) => ({ full_name: `${full_name} (${id})`, id }))
      // eslint-disable-next-line camelcase
      const responseList = res.data.data.map(({ full_name, id }) => ({ full_name: `${full_name} (${id})`, id }))
      const mergeList = _.concat(responseList, selectedClinic)
      const uniqueRes = _.uniqBy(mergeList, e => e.id)

      vm.$emit('update:doctorOptions', uniqueRes)
      loading(false)
    }, 350),
    genUrl(doctorData) {
      // eslint-disable-next-line radix
      const findCity = _.find(this.citiesList, city => city.id === parseInt(doctorData.city_id))

      if (doctorData.main_speciality === undefined) {
        return null
      }
      // eslint-disable-next-line radix
      const findSpeciality = _.find(this.specialitiesList, speciality => speciality.id === parseInt(doctorData.main_speciality))

      return `https://vrach-russia.ru/${findCity.slug}/${findSpeciality.slug}/${doctorData.id}-${doctorData.slug}/`
    },
    formSubmitClose() {
      this.$emit('formSubmitClose', {
        reload: true,
      })
    },
    formSubmitReload() {
      this.$emit('formSubmitReload', {
        reload: false,
      })
    },
    getNameClinicById(clinicId) {
      // eslint-disable-next-line radix
      return _.find(this.clinicOptions, clinic => clinic.id === parseInt(clinicId))?.name ?? ''
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
