<template>
  <b-card>
    <b-overlay
      :show="loading"
      variant="white"
      spinner-variant="primary"
      rounded="lg"
      :opacity=".7"
    >
      <ValidationObserver
        ref="form"
      >
        <Form
          :doctor-data.sync="formData"
          :cities-list="citiesList"
          :specialities-list="specialitiesList"
          :clinic-options.sync="clinicOptions"
          :doctor-options.sync="doctorOptions"
          :selected-options.sync="selectedOptions"
          @formSubmitClose="doFormSave"
          @formSubmitReload="doFormSave"
        />
      </ValidationObserver>
    </b-overlay>
  </b-card>
</template>

<script>
import { BCard, BOverlay } from 'bootstrap-vue'
import _ from 'lodash'
import axiosIns from '@/libs/axios'
import store from '@/store'
import { FormDataConverter } from '@/mixins/formData'
import Form from './Form.vue'

const emptyFormData = Object.freeze({
  id: null,
  slug: '',
  full_name: '',
  city_id: null,
  id_docdoc: null,
  status_docdoc: null,
  type: null,
  email: '',
  phone: '',
  photo: '',
  gender: null,
  degree: null,
  category: null,
  experience_start: null,
  education: '',
  courses: '',
  price: null,
  description: '',
  count_views: 0,
  is_show: null,
  note: '',
  clinics: [],
  clinic_ids: [],
  main_speciality: null,
  other_specialities: null,
  redirect_id: null,
  redirect_doctor: null,
})

export default {
  name: 'DoctorsUpdate',
  components: {
    BCard,
    BOverlay,
    Form,
  },
  metaInfo() {
    return {
      title: this.getTitlePage(),
    }
  },
  data() {
    return {
      formData: _.cloneDeep(emptyFormData),
      loading: true,
      serverErrors: {},
      citiesList: [],
      specialitiesList: [],
      clinicOptions: [],
      doctorOptions: [],
      selectedOptions: [],
    }
  },
  mounted() {
    Promise.all([
      this.doLoadCities(),
      this.doLoadSpecialities(),
    ]).then(() => {
      Promise.all([
        this.doLoadDoctor(),
      ]).then(() => {
        this.loading = false
      })
    })
  },
  beforeDestroy() {
    store.commit('app/UPDATE_CURRENT_PAGE_TITLE', null)
  },
  methods: {
    async doFormSave(callbackData) {
      this.loading = true

      if (typeof this.formData.photo === 'string') { this.formData.photo = null }
      if (this.formData.category === null) { this.formData.category = '' }
      if (this.formData.degree === null) { this.formData.degree = '' }

      const formData = new FormDataConverter(this.formData)

      try {
        const res = await axiosIns({
          method: 'POST',
          url: `/doctors/${this.$route.params.id}/update`,
          data: formData.getData(),
        })
        this.loading = false
        if (res.data.status === 'success') {
          if (callbackData.reload === false) {
            await this.doLoadDoctor()

            this.sendToast({
              title: 'Успех!',
              icon: 'CheckCircleIcon',
              text: 'Врач успешно обновлен!',
              variant: 'success',
            })
            return
          }

          await this.$router.push({ name: 'doctors' })

          this.sendToast({
            title: 'Успех!',
            icon: 'CheckCircleIcon',
            text: 'Врач успешно обновлен!',
            variant: 'success',
          })
        }
      } catch (error) {
        console.log(error)
        this.loading = false
        if (error.response.status === 500) {
          this.sendToast({
            title: 'Ошибка!',
            icon: 'AlertCircleIcon',
            text: 'Ошибка на сервере, попробуйте еще раз!',
            variant: 'danger',
          })
        }
        if (error.response.status === 422) {
          this.serverErrors = error.response.data.data
          this.$refs.form.setErrors(this.serverErrors)
          this.sendToast({
            title: 'Ошибка!',
            icon: 'AlertCircleIcon',
            text: 'Не все поля заполнены!',
            variant: 'danger',
          })
        }
      }
    },
    async doLoadDoctor() {
      this.loading = true
      const response = await axiosIns({
        method: 'GET',
        url: `/doctors/${this.$route.params.id}/show`,
      })

      this.formData = _.cloneDeep(emptyFormData)
      this.formData = _.merge(this.formData, response.data.data)

      if (this.formData.logo_doctor !== null) {
        this.formData.photo = `${this.$imageBaseUrl + this.formData.logo_doctor.path_dirname}/${this.formData.logo_doctor.path_filename}`
      }

      if (this.formData.clinics !== null) {
        this.formData.clinics = this.formData.clinics?.map(({ name, id }) => ({ name, id }))
        this.formData.clinic_ids = this.formData.clinics?.map(({ id }) => (id))
        this.selectedOptions.push(...this.formData.clinics)
        this.clinicOptions = []
        this.clinicOptions.push(...this.formData.clinics)
      }

      if (this.formData.redirect_doctor !== null) {
        this.doctorOptions = [{ full_name: `${this.formData.redirect_doctor.full_name} (${this.formData.redirect_doctor.id})`, id: this.formData.redirect_doctor.id }]
      }

      this.formData.main_speciality = this.formData.main_speciality?.id
      this.formData.other_specialities = _.map(this.formData.specialities, 'id')

      store.commit('app/UPDATE_CURRENT_PAGE_TITLE', this.formData.full_name)
      this.loading = false
      return response
    },
    async doLoadCities() {
      const response = await axiosIns({
        method: 'GET',
        url: '/cities/options',
      })

      this.citiesList = response.data.data

      return response
    },
    async doLoadSpecialities() {
      const response = await axiosIns({
        method: 'GET',
        url: '/doctor_specialisations/options',
      })

      this.specialitiesList = response.data.data

      return response
    },
    getTitlePage() {
      return `${this.formData?.full_name ?? ''} - Редактирование врача`
    },
  },
}
</script>
