var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[(_vm.doctorData.main_speciality === undefined)?_c('b-alert',{attrs:{"variant":"danger","show":""}},[_c('div',{staticClass:"alert-body"},[_c('span',[_c('strong',[_vm._v("Внимание!")]),_vm._v(" Выберите основную специальность у врача и сохраните страницу!")])])]):_vm._e(),((_vm.doctorData.redirect_id !== null && _vm.doctorData.redirect_id !== undefined) && (_vm.doctorData.redirect_doctor !== null && _vm.doctorData.redirect_doctor !== undefined))?_c('b-alert',{attrs:{"variant":"danger","show":""}},[_c('div',{staticClass:"alert-body"},[_c('span',[_c('strong',[_vm._v("Внимание!")]),_vm._v(" Врач редиректит на "),_c('router-link',{attrs:{"to":{name: 'doctors-update', params: {id: _vm.doctorData.redirect_doctor.id}}}},[_c('b',[_vm._v(_vm._s(_vm.doctorData.redirect_doctor.full_name))])]),_vm._v("!")],1)])]):_vm._e(),_c('b-row',[_c('b-col',{staticClass:"text-center",attrs:{"cols":"3"}},[_c('b-row',[_c('b-col',[_c('b-avatar',{ref:"previewEl",attrs:{"id":"photoPreview","src":_vm.photoPreview,"size":"200px","variant":"light-primary","rounded":""}})],1)],1),_c('b-row',[_c('b-col',[_c('b-button',{staticClass:"mt-1 btn-block mb-1",attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.openFileDialog('inputPhoto')}}},[_c('span',{staticClass:"d-sm-inline"},[_vm._v("Выбрать")]),_c('b-form-file',{ref:"inputPhoto",staticClass:"text-left d-none",attrs:{"accept":"image/jpeg, image/png, image/gif","size":"sm"},on:{"change":_vm.fileChanged}})],1)],1)],1)],1),_c('b-col',{attrs:{"cols":"9"}},[_c('b-form-group',{attrs:{"label":"Ф.И.О.:","label-for":"full_name"}},[_c('ValidationProvider',{attrs:{"name":"full_name","vid":"full_name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","autocomplete":"off","placeholder":"Введите ФИО"},model:{value:(_vm.doctorData.full_name),callback:function ($$v) {_vm.$set(_vm.doctorData, "full_name", $$v)},expression:"doctorData.full_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"ЧПУ URL:","label-for":"slug"}},[_c('ValidationProvider',{attrs:{"name":"slug","vid":"slug"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"slug","autocomplete":"off","placeholder":"Введите ЧПУ URL"},model:{value:(_vm.doctorData.slug),callback:function ($$v) {_vm.$set(_vm.doctorData, "slug", $$v)},expression:"doctorData.slug"}}),(_vm.doctorData.id && _vm.doctorData.slug && (_vm.genUrl(_vm.doctorData) !== null))?_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"primary","href":_vm.genUrl(_vm.doctorData),"target":"_blank"}},[_vm._v(" Перейти ")])],1):_vm._e()],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Город:","label-for":"city_id"}},[_c('ValidationProvider',{attrs:{"name":"city_id","vid":"city_id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"name","reduce":function (option) { return option.id; },"options":_vm.citiesList},model:{value:(_vm.doctorData.city_id),callback:function ($$v) {_vm.$set(_vm.doctorData, "city_id", $$v)},expression:"doctorData.city_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Пол:","label-for":"gender"}},[_c('ValidationProvider',{attrs:{"name":"gender","vid":"gender"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"city_id","value-field":"id","text-field":"value","options":[{id: null, value: 'Не выбрано'} ].concat( _vm.DoctorsConstants.GENDER_LIST)},model:{value:(_vm.doctorData.gender),callback:function ($$v) {_vm.$set(_vm.doctorData, "gender", $$v)},expression:"doctorData.gender"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"E-Mail:","label-for":"email"}},[_c('ValidationProvider',{attrs:{"name":"email","vid":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","autocomplete":"off","placeholder":"Введите E-mail"},model:{value:(_vm.doctorData.email),callback:function ($$v) {_vm.$set(_vm.doctorData, "email", $$v)},expression:"doctorData.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Основная специальность:","label-for":"main_speciality"}},[_c('ValidationProvider',{attrs:{"name":"main_speciality","vid":"main_speciality"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"main_speciality","value-field":"id","text-field":"name","options":[{id: null, name: 'Не выбрано'} ].concat( _vm.specialitiesList)},model:{value:(_vm.doctorData.main_speciality),callback:function ($$v) {_vm.$set(_vm.doctorData, "main_speciality", $$v)},expression:"doctorData.main_speciality"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Дополнительные специальности:","label-for":"other_specialities"}},[_c('ValidationProvider',{attrs:{"name":"other_specialities","vid":"other_specialities"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"multiple":"","label":"name","reduce":function (option) { return option.id; },"options":_vm.specialitiesList,"placeholder":"Выберите доп специальности"},model:{value:(_vm.doctorData.other_specialities),callback:function ($$v) {_vm.$set(_vm.doctorData, "other_specialities", $$v)},expression:"doctorData.other_specialities"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Категория:","label-for":"category"}},[_c('ValidationProvider',{attrs:{"name":"category","vid":"category"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"category","value-field":"id","text-field":"value","options":[{id: null, value: 'Не выбрано'} ].concat( _vm.DoctorsConstants.CATEGORY_LIST)},model:{value:(_vm.doctorData.category),callback:function ($$v) {_vm.$set(_vm.doctorData, "category", $$v)},expression:"doctorData.category"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Степень:","label-for":"degree"}},[_c('ValidationProvider',{attrs:{"name":"degree","vid":"degree"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"degree","value-field":"id","text-field":"value","options":[{id: null, value: 'Не выбрано'} ].concat( _vm.DoctorsConstants.DEGREE_LIST)},model:{value:(_vm.doctorData.degree),callback:function ($$v) {_vm.$set(_vm.doctorData, "degree", $$v)},expression:"doctorData.degree"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Стаж с:","label-for":"experience_start"}},[_c('ValidationProvider',{attrs:{"name":"experience_start","vid":"experience_start"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"experience_start","value-field":"id","text-field":"value","options":_vm.stagList},model:{value:(_vm.doctorData.experience_start),callback:function ($$v) {_vm.$set(_vm.doctorData, "experience_start", $$v)},expression:"doctorData.experience_start"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Тип врача:","label-for":"type"}},[_c('ValidationProvider',{attrs:{"name":"type","vid":"type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"type","value-field":"id","text-field":"value","options":[{id: null, value: 'Не выбрано'} ].concat( _vm.DoctorsConstants.TYPE_LIST)},model:{value:(_vm.doctorData.type),callback:function ($$v) {_vm.$set(_vm.doctorData, "type", $$v)},expression:"doctorData.type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Места работы (клиники):","label-for":"clinics"}},[_c('ValidationProvider',{attrs:{"name":"cliniсs","vid":"cliniсs"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"clinics","label":"name","reduce":function (option) { return option.id; },"options":_vm.clinic_options,"multiple":"","disabled":_vm.doctorData.city_id === null},on:{"search":_vm.onSearch},model:{value:(_vm.doctorData.clinic_ids),callback:function ($$v) {_vm.$set(_vm.doctorData, "clinic_ids", $$v)},expression:"doctorData.clinic_ids"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"mb-2"},_vm._l((_vm.doctorData.clinic_ids),function(clinic_id,index){return _c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.doctorData.clinic_ids.length > 0),expression:"doctorData.clinic_ids.length > 0"}],key:clinic_id.id},[_c('router-link',{attrs:{"to":{name: 'clinics-update', params: {id: clinic_id}},"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.getNameClinicById(clinic_id))),(index !== _vm.doctorData.clinic_ids.length - 1)?_c('span',[_vm._v(",  ")]):_vm._e()])],1)}),0)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Образование:","label-for":"education"}},[_c('ValidationProvider',{attrs:{"name":"education","vid":"education"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{ref:"doctorData.education",attrs:{"id":"education","placeholder":"Введите описание образование","rows":"3"},model:{value:(_vm.doctorData.education),callback:function ($$v) {_vm.$set(_vm.doctorData, "education", $$v)},expression:"doctorData.education"}}),_c('div',{staticClass:"d-flex justify-content-between align-items-baseline"},[_c('fast-insert',{attrs:{"input-ref":_vm.$refs['doctorData.education'],"suggestions":_vm.configSuggestions,"input-ref-name":"doctorData.education"},on:{"fastInsertSelected":_vm.onSelectedFastInsertSuggestion}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Курсы:","label-for":"courses"}},[_c('ValidationProvider',{attrs:{"name":"courses","vid":"courses"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{ref:"doctorData.courses",attrs:{"id":"courses","placeholder":"Введите описание курсов","rows":"3"},model:{value:(_vm.doctorData.courses),callback:function ($$v) {_vm.$set(_vm.doctorData, "courses", $$v)},expression:"doctorData.courses"}}),_c('div',{staticClass:"d-flex justify-content-between align-items-baseline"},[_c('fast-insert',{attrs:{"input-ref":_vm.$refs['doctorData.courses'],"suggestions":_vm.configSuggestions,"input-ref-name":"doctorData.courses"},on:{"fastInsertSelected":_vm.onSelectedFastInsertSuggestion}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Дополнительно:","label-for":"description"}},[_c('ValidationProvider',{attrs:{"name":"description","vid":"description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"description","placeholder":"Введите описание","rows":"3"},model:{value:(_vm.doctorData.description),callback:function ($$v) {_vm.$set(_vm.doctorData, "description", $$v)},expression:"doctorData.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Описание (для нас):","label-for":"note"}},[_c('ValidationProvider',{attrs:{"name":"note","vid":"note"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"note","placeholder":"Введите заметку (только для нас)","rows":"3"},model:{value:(_vm.doctorData.note),callback:function ($$v) {_vm.$set(_vm.doctorData, "note", $$v)},expression:"doctorData.note"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"ID docdoc:","label-for":"id_docdoc"}},[_c('ValidationProvider',{attrs:{"name":"id_docdoc","vid":"id_docdoc"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"id_docdoc","autocomplete":"off","placeholder":"ID в базе Сбер здоровье"},model:{value:(_vm.doctorData.id_docdoc),callback:function ($$v) {_vm.$set(_vm.doctorData, "id_docdoc", $$v)},expression:"doctorData.id_docdoc"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Тип docdoc:","label-for":"status_docdoc"}},[_c('ValidationProvider',{attrs:{"name":"status_docdoc","vid":"status_docdoc"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"is_show","value-field":"id","text-field":"value","options":_vm.DoctorsConstants.TYPE_DOCDOC_LIST},model:{value:(_vm.doctorData.status_docdoc),callback:function ($$v) {_vm.$set(_vm.doctorData, "status_docdoc", $$v)},expression:"doctorData.status_docdoc"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Статус доктора:","label-for":"is_show"}},[_c('ValidationProvider',{attrs:{"name":"is_show","vid":"is_show"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"is_show","disabled":_vm.doctorData.redirect_id ? true : false,"value-field":"id","text-field":"value","options":_vm.DoctorsConstants.STATUS_DOCTORS_LIST},model:{value:(_vm.doctorData.is_show),callback:function ($$v) {_vm.$set(_vm.doctorData, "is_show", $$v)},expression:"doctorData.is_show"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Редирект на:","label-for":"redirect_id"}},[_c('ValidationProvider',{attrs:{"name":"redirect_id","vid":"redirect_id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"doctor_id","label":"full_name","reduce":function (option) { return option.id; },"options":_vm.redirect_options,"disabled":!_vm.doctorData.city_id},on:{"search":_vm.onSearchDoctors},model:{value:(_vm.doctorData.redirect_id),callback:function ($$v) {_vm.$set(_vm.doctorData, "redirect_id", $$v)},expression:"doctorData.redirect_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('div',{staticClass:"d-flex flex-row-reverse"},[_c('div',[_c('b-button',{staticClass:"float-right",attrs:{"variant":"primary"},on:{"click":_vm.formSubmitClose}},[_vm._v(" "+_vm._s(_vm.doctorData.id ? 'Обновить и закрыть' : 'Создать и закрыть')+" ")])],1),(_vm.doctorData.id)?_c('div',[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"primary"},on:{"click":_vm.formSubmitReload}},[_vm._v(" Обновить ")])],1):_vm._e(),_c('div',[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"flat-danger"},on:{"click":function($event){return _vm.$router.back()}}},[_vm._v(" Отменить ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }